import { CreateControllerFn } from 'yoshi-flow-editor-runtime';

import { Interaction } from '../../types';
import { createPublicAPIStore } from '../../services/public-api-store';
import {
  getInstanceFactory,
  getMetaData,
  getMetaSiteId,
  getCurrentMemberId,
  getViewedMemberId,
  setComponentSettings,
  setSitePresets,
  initServices,
  initProps,
  emitAppLoadedBIEvent,
} from '../../services/controller-utils';
import { initBILogger } from '../../services/bi-logger';
import { initBlogBILogger } from '../../services/blog-bi-logger';
import { initMonitoringService } from '../../services/monitoring';
import { createDataSyncService } from '../../services/data-sync-service';
import { createInitialDataFetchService } from '../../services/initial-data-fetch-service';
import {
  createSettingsListener,
  registerSettingsListeners,
  registerStoreChangeListener,
  registerCurrentUserListener,
  registerDataSyncListener,
} from '../../services/controller-listeners';
import createStore from '../../store';

const noop = () => {};

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}) => {
  const { compId, appParams, wixCodeApi, platformAPIs } = controllerConfig;
  const { config } = controllerConfig;
  const { viewMode } = wixCodeApi.window;
  const getInstance = getInstanceFactory(controllerConfig);
  const getPublicAPI = createPublicAPIStore(wixCodeApi.site.getPublicAPI);
  const metaData = getMetaData(getInstance);
  const services = initServices(compId, flowAPI, getInstance);
  const biLogger = initBILogger(platformAPIs.biLoggerFactory);
  const blogBILogger = initBlogBILogger(flowAPI);
  const experiments = await flowAPI.getExperiments();
  const settingsListener = createSettingsListener(config.publicData);
  const dataSyncService = createDataSyncService(compId, platformAPIs.pubSub);
  const initialDataFetchService = createInitialDataFetchService({
    flowAPI,
    getInstance,
    services,
    experiments,
  });

  const store = createStore({
    ...services,
    metaData,
    compId,
    flowAPI,
    wixCodeApi,
    platformAPIs,
    experiments,
    dataSyncService,
    initialDataFetchService,
    getPublicAPI,
    blogBILogger: viewMode === 'Site' ? blogBILogger : null,
    biLogger: viewMode === 'Site' ? biLogger : null,
  });

  return {
    async pageReady() {
      const currentMemberId = getCurrentMemberId(wixCodeApi, metaData);
      const viewedMemberId = getViewedMemberId(controllerConfig, metaData);
      const metaSiteId = getMetaSiteId(controllerConfig, metaData);
      const monitoringService = initMonitoringService(flowAPI);

      const currentUserListenerOptions = {
        store,
        flowAPI,
        initialDataFetchService,
        viewedMemberId,
      };

      const initPropsOptions = {
        currentMemberId,
        viewedMemberId,
        store,
        initialDataFetchService,
      };

      await monitoringService
        .toMonitored(Interaction.InitialDataLoad, initProps(initPropsOptions))
        .catch(noop);

      const renderWidget = async () => {
        setComponentSettings(store, controllerConfig.config.style.styleParams);
        registerCurrentUserListener(currentUserListenerOptions);
        registerSettingsListeners({
          eventHandler: settingsListener,
          dataSyncService,
          store,
          experiments,
        });
        registerStoreChangeListener({
          metaSiteId,
          store,
          experiments,
          controllerConfig,
          flowAPI,
          dataSyncService,
        });
        registerDataSyncListener(dataSyncService, store);
        emitAppLoadedBIEvent({
          widgetId: appParams.appDefinitionId,
          store,
          metaData,
          biLogger,
          flowAPI,
        });
      };

      await monitoringService
        .toMonitored(Interaction.InitialWidgetRender, renderWidget())
        .catch(noop);
    },
    updateConfig(_, { style, publicData }) {
      settingsListener.notify(publicData.COMPONENT || {});

      if (style?.styleParams) {
        const { styleParams, ...sitePresets } = style;
        setComponentSettings(store, style.styleParams);
        setSitePresets(store, sitePresets);
      }
    },
    onBeforeUnLoad() {
      dataSyncService.unregisterListeners();
    },
  };
};

export default createController;
